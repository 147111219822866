.pagination span {
    width          : 2rem;
    height         : 2rem;
    padding        : 5px;
    border-radius  : 50px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    border         : 1px solid var(--secondary-color);
    color          : var(--heading-color);
    font-size      : 1rem;
    cursor         : pointer;
    font-weight    : 500;
 }
 
 .active__page {
    background: var(--secondary-color);
    color     : #fff !important;
 }